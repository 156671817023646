import React from "react";

function FlashMessages(props) {

  return (
    <div className="floating-alerts">
        <h1>
          {props.messages.map((msg, index) => {
            return (
              <div key={index} className="floating-alert">
                {msg}
              </div>
            );
          })}
        </h1>
    </div>
  );
}

export default FlashMessages


