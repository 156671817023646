import React, { useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container } from "@material-ui/core";
import Page from "./Page";
import { API, Auth } from "aws-amplify";
import { useParams, Link, withRouter } from "react-router-dom";
import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";
import { useImmerReducer } from "use-immer";
import NotFound from "./NotFound";
import Loading from "./Loading";

const useStyles = makeStyles(theme => ({
  paper: {
    margin: "40px auto",
    width: "75%",
    minHeight: "80vh"
  },
  back: {
    width: "150px",
    margin: "15px 25px"
  },
  backLink: {
    color: "white",
    textDecoration: "none"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    width: "150px",
    margin: theme.spacing(3, 0, 2)
  }
}));

function EditPost(props) {
  const classes = useStyles();
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const { postID, post, title, timestamp, username} = useParams();


  const originalState = {
    title: {
      value: "",
      hasErrors: false,
      message: ""
    },
    post: {
      value: "",
      hasErrors: false,
      message: ""
    },
    username: "",
    timestamp: 0,
    isFetching: true,
    isSaving: false,
    postID: postID,
    sendCount: 0,
    notFound: false
  };

  function myReducer(draft, action) {
    switch (action.type) {
      case "fetchComplete":
        draft.title.value = action.value.title;
        draft.post.value = action.value.post;
        draft.timestamp = action.value.timestamp;
        draft.username = action.value.username;
        draft.isFetching = false;
        return;
      case "titleChange":
        draft.title.hasErrors = false;
        draft.title.value = action.value;
        return;
      case "postChange":
        draft.post.hasErrors = false;
        draft.post.value = action.value;
        return;
      case "submitRequest":
        if (!draft.title.hasErrors && !draft.post.hasErrors) {
          draft.sendCount++;
        }
        return;
      case "saveRequestStarted":
        draft.isSaving = true;
        return;
      case "saveRequestFinished":
        draft.isSaving = false;
        return;
      case "titleRules":
        if (!action.value.trim()) {
          draft.title.hasErrors = true;
          draft.title.message = "You must provide a title.";
        }
        return;
      case "postRules":
        if (!action.value.trim()) {
          draft.post.hasErrors = true;
          draft.post.message = "You must provide post content.";
        }
        return;
      case "notFound":
        draft.notFound = true;
        return;
    }
  }
  const [state, dispatch] = useImmerReducer(myReducer, originalState);
 
  function submitHandler(e) {
    e.preventDefault();
    dispatch({ type: "titleRules", value: state.title.value });
    dispatch({ type: "postRules", value: state.post.value });
    dispatch({ type: "submitRequest" });
  }

  useEffect(() => {
    async function fetchPost() {
      try {
        let apiName = "mqsAPI";
        let apiPath = `/note/n/${postID}`;
        let apiOptions = {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          },
          pathParameters: { postID: postID }
        };

       await API.get(apiName, apiPath, apiOptions)
          .then(response => {
            if (response.Items[0]) {
              dispatch({ type: "fetchComplete", value: response.Items[0] });
               if (appState.user.username != response.Items[0].username) {
            appDispatch({ type: "flashMessage", value: "You do not have permission to edit that post." });
            props.history.push("/");
            }
          }else{
              dispatch({type: "notFound"});
            }
          })
          .catch(console.error);
      } catch (err) {
        console.log("There was an error or the request was cancelled", err);
      }
    }
    fetchPost();
  }, [postID]);
  

  useEffect(() => {
    if (state.sendCount) {
      dispatch({ type: "saveRequestStarted" });

      async function fetchPost() {
        console.log("timestamp: ", state.timestamp);
        try {
          let apiName = "mqsAPI";
          let apiPath = "/note";
          let apiOptions = {
            headers: {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`
            },
            body: {
              post: state.post.value,
              title: state.title.value,
              postID: postID,
              timestamp: state.timestamp,
              username: state.username
            }
          };
          await API.put(apiName, apiPath, apiOptions);

          dispatch({ type: "saveRequestFinished" });
          appDispatch({ type: "flashMessage", value: "Post was updated." });
        } catch (e) {
          console.log(
            "There was a problem or the update request was cancelled.", e);
        }
      }
      fetchPost();
    }
  }, [state.sendCount]);

  if (state.notFound) {
    return <NotFound />;
  }

  if (state.isFetching)
    return (
      <Page title="...">
        <Loading/>
      </Page>
    );

  const btnLink = `/post/${state.postID}`;

  return (
    <Page title="Edit Post">
      <Paper elevation={3} className={classes.paper}>
        <Button className={classes.back} color="primary" variant="contained">
          <Link to={btnLink} className={classes.backLink}>
            &laquo; Back to Post
          </Link>
        </Button>
        <Container>
          <form className={classes.form} onSubmit={submitHandler}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="title"
              value={state.title.value}
              onBlur={e =>
                dispatch({ type: "titleRules", value: e.target.value })
              }
              onChange={e =>
                dispatch({ type: "titleChange", value: e.target.value })
              }
              label="Title"
              name="title"
              autoComplete="off"
              autoFocus
            />
            {state.title.hasErrors && (
              <div>
                <Typography>{state.title.message}</Typography>
              </div>
            )}
            <TextField
              className={classes.postInput}
              variant="outlined"
              margin="normal"
              multiline
              rows="12"
              required
              fullWidth
              name="postBody"
              label="Post Body"
              type="postBody"
              id="post"
              value={state.post.value}
              onBlur={e =>
                dispatch({ type: "postRules", value: e.target.value })
              }
              onChange={e =>
                dispatch({ type: "postChange", value: e.target.value })
              }
            />
            {state.post.hasErrors && (
              <div>
                <Typography>{state.post.message}</Typography>
              </div>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={state.isSaving}
            >
              Save Updates
            </Button>
          </form>
        </Container>
      </Paper>
    </Page>
  );
}

export default withRouter(EditPost);
