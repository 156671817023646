import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  footer: {
    color: "white",
    background: "rgb(67, 67, 122)",
    display: "flex",
    justifyContent: "space-around"
  },
  footerP: {
    margin: "20px 10px"
  }
}));

function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <p className={classes.footerP}>
        Copyright &copy; 2020 LenkinWeb. All rights reserved.
      </p>
    </div>
  );
}

export default Footer;
