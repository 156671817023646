import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
  btn1: {
    color: "white",
    height: 45,
    width: 120,
    padding: "10px",
    margin: "10px",
    border: "1px solid white",
    letterSpacing: "3px",
    backgroundColor: "#008000",
    fontSize: "12px",
    "&:hover": {
      fontWeight: 700,
      border: "2px solid #white",
      backgroundColor: "#1c54b2"
    }
  },
  btn2: {
    color: "white",
    height: 45,
    width: 120,
    padding: "10px",
    margin: "10px",
    border: "1px solid white",
    letterSpacing: "3px",
    fontSize: "12px",
    "&:hover": {
      fontWeight: 700,
      border: "2px solid #00cc00"
    }
  }
}));

function HeaderLoggedOut() {
const classes = useStyles();

  return (
    <div>

      <Button className={classes.btn2} href="/sign-up">
        Sign Up
      </Button>
      <Button className={classes.btn1} href="/blog">
        Sign In
      </Button>
    </div>
  );
}

export default HeaderLoggedOut;
