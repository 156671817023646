import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Page from "./Page";
import CategoryNavbar from "./CategoryNavbar";
import Category from "./Category";

const useStyles = makeStyles(theme => ({
  categoryDiv: {
    margin: "40px auto",
    width: "75%"
  },
  image: {
    backgroundImage:
      "url(https://myrandompictures.s3-ap-southeast-2.amazonaws.com/zen.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100wv",
    margin: "0",
    height: "75vh",
    width: "auto"
  },
  mainTitle: {
    color: "white",
    fontSize: "60px",
    marginLeft: "50px",
    paddingTop: "5%",
    width: "50%"
  },
  tone: {
    background: "rgba(20, 120, 30, 0.3)",
    height: "100%"
  },
  explanation: {
    color: "white",
    fontSize: "30px",
    padding: "20px 50px",
    width: "50%"
  }
}));

function Home() {
  const classes = useStyles();
  return (
    <Page title="Home">
      <div className={classes.image}>
        <div className={classes.tone}>
          <Typography className={classes.mainTitle}>
            MEDITATION, QIGONG AND SCIENCE
          </Typography>
          <Typography className={classes.explanation}>
            Scientifically proven facts about meditation, qigong, acupuncture,
            yoga and other related areas
          </Typography>
        </div>
      </div>
        <CategoryNavbar />
        <div className={classes.categoryDiv}>
        <Category sectionID="meditation" />
        <Category sectionID="qigong" />
        <Category sectionID="acupuncture" />
        <Category sectionID="yoga" />
      </div>
    </Page>
  );
}

export default Home;
