import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Page from './Page';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: "40px auto",
    width: "75%",
    height: "75vh"
  },
  header: {
      padding: "60px 15%"
  }
}));

function NotFound() {
  const classes = useStyles();
  return (
    <Page title="Not Found">
      <Paper className={classes.paper}>
        <Typography className={classes.header} variant="h3">
          Whoops, we cannot find that page.
        </Typography>
      </Paper>
    </Page>
  );
}

export default NotFound;
