import React, { useEffect, useState, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import {
  useParams,
  Link,
  Switch,
  Route
} from "react-router-dom";
import ProfilePosts from "./ProfilePosts";
import ProfileFollowers from "./ProfileFollowers";
import ProfileFollowing from "./ProfileFollowing";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Page from './Page';
import StateContext from '../StateContext';
import DispatchContext from "../DispatchContext";
import { API, Auth } from "aws-amplify";
import { useImmer } from "use-immer";


const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "85vh"
  },
  avatarImg: {
    height: "90px",
    width: "90px",
    borderRadius: "50%"
  },
  avatarBox: {
    display: "flex",
    margin: "50px 0"
  },
  username: {
    color: "#262673",
    margin: "25px 5px 5px 20px"
  },
  followBtn: {
    height: "40px",
    marginTop: "35px",
    "&:hover": {
      color: "#e6005c"
    }
  },
  tab: {
    borderRight: "1px solid #c2c2d6",
    backgroundColor: "#ebebfa",
    color: "#33334d",
    "&:focus": {
      backgroundColor: "white"
    }
  },
  appBar: {
    marginBottom: "30px"
  }
}));

function Profile() {
  const classes = useStyles();

  const { username } = useParams();
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  const [state, setState] = useImmer({
    followActionLoading: false,
    startFollowingRequestCount: 0,
    stopFollowingRequestCount: 0,
    profileData: {
      follower: [],
      following: [],
      profileUsername: "...",
      profileAvatar:
        "https://myrandompictures.s3-ap-southeast-2.amazonaws.com/green.jpg",
      counts: { postCount: 0, followerCount: 0, followingCount: 0 }
    }
  });

  const theme = useTheme();
  let apiName = "mqsAPI";
  let apiPath1 = "/notes";
  let apiPath2 = "/follower";
  let apiPath3 = "/following";

  //GET
  //get posts
  useEffect(() => {
    async function fetchData() {
      try {
        let apiOptions = {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          },
          queryStringParameters: { username: username }
        };
        await API.get(apiName, apiPath1, apiOptions)
          .then(response => {
            let imgUrl =  "https://mqsavatars.s3-ap-southeast-2.amazonaws.com/" +
                response.usr + ".jpg";         
            setState(draft => {
              draft.profileData.counts.postCount = response.data_p.Items.length;
              draft.profileData.profileUsername = response.usr;
              draft.profileData.profileAvatar = imgUrl;
            });
            appDispatch({type: "avatar", value: imgUrl});
          })
          .catch(console.error);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [username]);

  //get followers
  useEffect(() => {
    async function fetchData() {
      try {
        let apiOptions = {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          },
          queryStringParameters: { username: username }
        };
        await API.get(apiName, apiPath2, apiOptions)
          .then(response => {
            setState(draft => {
              draft.profileData.counts.followerCount = response.length;
              draft.profileData.follower = response;
            });
          })
          .catch(console.error);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [username]);

  //get following
  useEffect(() => {
    async function fetchData() {
      try {
        let apiOptions = {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          },
          queryStringParameters: { username: username }
        };
          await API.get(apiName, apiPath3, apiOptions)
            .then(response => {
              setState(draft => {
                draft.profileData.counts.followingCount = response.length;
                draft.profileData.following = response;
              });
            })
            .catch(console.error);
      } catch (e) {
        console.log(e);
      }
    }
    fetchData();
  }, [username]);

  //add follower

  useEffect(() => {
    if (state.startFollowingRequestCount) {
      setState(draft => {
        draft.followActionLoading = true;
      });

      async function fetchData() {
        try {
          setState(draft => {
            draft.profileData.counts.followerCount++;
            draft.followActionLoading = false;
          });
        } catch (e) {
          console.log(e);
        }
      }
      fetchData();
    }
  }, [state.startFollowingRequestCount]);

  //remove follower

  useEffect(() => {
    if (state.stopFollowingRequestCount) {
      setState(draft => {
        draft.followActionLoading = true;
      });

      async function fetchData() {
        try {
          setState(draft => {
            draft.profileData.counts.followerCount--;
            draft.followActionLoading = false;
          });
        } catch (e) {
          console.log(e);
        }
      }
      fetchData();
    }
  }, [state.stopFollowingRequestCount]);

  //POST

  async function sendFollower() {
    try {
      let apiName = "mqsAPI";
      let apiPath1 = "/follower";
      let apiPath2 = "/following";
      let apiOptions1 = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        body: {
          username: username,
          follower: appState.user.username
        }
      };
      let apiOptions2 = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        body: {
          username: appState.user.username,
          following: username
        }
      };
      await API.post(apiName, apiPath1, apiOptions1);
      await API.post(apiName, apiPath2, apiOptions2);
    } catch (err) {
      console.log(err);
    }
  }

  function startFollowing() {
    sendFollower();
    if (state.startFollowingRequestCount == 0) {
      setState(draft => {
        draft.startFollowingRequestCount++;
      });
    }
  }

  //timestamps and found

  let followerTimestamp;
  let followingTimestamp;

  let followerList = state.profileData.follower;
  let followingList = state.profileData.following;

  let foundFollowerIndex = followerList.findIndex(
    a => a.follower == appState.user.username
  );

  let foundFollowingIndex = followingList.findIndex(
    a => a.following == state.profileData.profileUsername
  );

  if (foundFollowerIndex == -1) {
    followerTimestamp = "undefined";
  } else {
    followerTimestamp = followerList[foundFollowerIndex].timestamp;
  }

  if (foundFollowingIndex == -1) {
    followingTimestamp = "undefined";
  } else {
    followingTimestamp = followingList[foundFollowingIndex].timestamp;
  }

  //DELETE
  async function deleteFollower() {
    try {
      let apiName = "mqsAPI";
      let apiPath1 = "/follower";
      let apiPath2 = "/following";
      let apiOptions1 = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        body: {
          username: username,
          timestamp: followerTimestamp
        }
      };
      let apiOptions2 = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        body: {
          username: appState.user.username,
          timestamp: followingTimestamp
        }
      };
      await API.del(apiName, apiPath1, apiOptions1);
      await API.del(apiName, apiPath2, apiOptions2);
    } catch (err) {
      console.log("There was an error with deleting follower", err);
    }
  }

  //stop following
  function stopFollowing() {
    deleteFollower();
    setState(draft => {
      draft.stopFollowingRequestCount++;
    });
  }

  const postLabel = "Posts: " + state.profileData.counts.postCount;
  const followerLabel = "Followers: " + state.profileData.counts.followerCount;
  const followingLabel =
    "Following: " + state.profileData.counts.followingCount;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log("img Url", state.profileData.profileAvatar);
 
return (
  <Page title="Profile">
    <Container maxWidth="md" className={classes.root}>
      <div className={classes.avatarBox}>
        <img
          className={classes.avatarImg}
          alt="avatar"
          src={state.profileData.profileAvatar}
          onError={e => {
            e.target.src =
              "https://myrandompictures.s3-ap-southeast-2.amazonaws.com/green.jpg";
          }}
        />
        <Typography variant="h3" className={classes.username}>
          {state.profileData.profileUsername}
        </Typography>
        {appState.loggedIn &&
          foundFollowerIndex == -1 &&
          appState.user.username != state.profileData.profileUsername &&
          state.profileData.profileUsername != "..." && (
            <Button
              onClick={startFollowing}
              disabled={state.followActionLoading}
              className={classes.followBtn}
            >
              Follow
            </Button>
          )}
        {appState.loggedIn &&
          foundFollowerIndex !== -1 &&
          appState.user.username != state.profileData.profileUsername &&
          state.profileData.profileUsername != "..." && (
            <Button
              onClick={stopFollowing}
              disabled={state.followActionLoading}
              className={classes.followBtn}
            >
              Stop Following
            </Button>
          )}
      </div>

      <AppBar className={classes.appBar} position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs"
        >
          <Tab
            className={classes.tab}
            label={postLabel}
            component={Link}
            to={`/profile/${state.profileData.profileUsername}`}
          />
          <Tab
            className={classes.tab}
            label={followerLabel}
            component={Link}
            to={`/profile/${state.profileData.profileUsername}/followers`}
          />
          <Tab
            className={classes.tab}
            label={followingLabel}
            component={Link}
            to={`/profile/${state.profileData.profileUsername}/following`}
          />
        </Tabs>
      </AppBar>
      <Switch>
        <Route exact path="/profile/:username">
          <ProfilePosts />
        </Route>
        <Route path="/profile/:username/followers">
          <ProfileFollowers />
        </Route>
        <Route path="/profile/:username/following">
          <ProfileFollowing />
        </Route>
      </Switch>
    </Container>
  </Page>
);
}

export default Profile;
