import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";

import { Button, CssBaseline, TextField, Paper, Box, Grid, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StateContext from "../StateContext";
import Page from "./Page";
import {API, Auth} from 'aws-amplify';

const useStyles = makeStyles(theme => ({
  root: {
    height: "85vh"
  },
  image: {
    backgroundImage:
      "url(https://myrandompictures.s3-ap-southeast-2.amazonaws.com/lotus.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: "60px 40px 30px 40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(1, 0, 1)
  }
}));

function CreatePost(props) {
  const classes = useStyles();
  const appState = useContext(StateContext);
  const [title, setTitle] = useState();
  const [post, setPost] = useState();

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      let apiName="mqsAPI";
      let apiPath = "/note";
      let apiOptions = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        body: {
          post: post,
          title: title,
          username: appState.user.username
        }
      };
      await API.post(apiName, apiPath, apiOptions);
      props.history.push(`/profile/${appState.user.username}`);
    } catch (e) {
      console.log("There was a problem with POST:", e);
    }
  }

  return (
    <Page title="Create Post">
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={2} md={5} className={classes.image} />
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          component={Paper}
          elevation={5}
          square
        >
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Create Post
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="title"
                label="Title"
                name="title"
                autoComplete="title"
                autoFocus
                onChange={e => setTitle(e.target.value)}
              />
              <TextField
                className={classes.postInput}
                variant="outlined"
                margin="normal"
                multiline
                rows="12"
                required
                fullWidth
                name="post"
                label="Post Body"
                type="post"
                id="post"
                autoComplete="Post Body"
                onChange={e => setPost(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Save Post
              </Button>
              <Box mt={5}></Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </Page>
  );
}

export default withRouter(CreatePost);
