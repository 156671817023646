import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
  navbar: {
    color: "white",
    background: "rgb(67, 67, 122)",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px 0 20px"
  },
  navlinks: {
    display: "inline-block",
    color: "white",
    margin: "10px",
    paddingTop: "10px",
    textDecoration: "none",
    "&:hover": {
      color: "#ffd480"
    }
  }
}));

function CategoryNavbar() {
  const classes = useStyles();

  return (
    <div className={classes.navbar}>
      <div>
        <Link to="/#meditation" className={classes.navlinks}>
          <Typography>MEDITATION</Typography>
        </Link>{" "}
        <Link className={classes.navlinks} to="/#qigong">
          <Typography>QIGONG</Typography>
        </Link>{" "}
        <Link className={classes.navlinks} to="/#acupuncture">
          <Typography>ACCUPUNCTURE</Typography>
        </Link>
        <Link className={classes.navlinks} to="/#yoga">
          <Typography>YOGA</Typography>
        </Link>
      </div>
     
    </div>
  );
}

export default CategoryNavbar;
