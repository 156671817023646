import React, { useEffect, useState, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useParams, Link } from "react-router-dom";
import StateContext from "../StateContext";
import { API, Auth } from "aws-amplify";
import Loading from "./Loading";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  avatarDiv: {
    display: "flex",
    margin: "30px 0",
    borderBottom: "1px solid #dcdde0"
  },
  avatar: {
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    marginRight: "20px",
    marginBottom: "10px"
  },
  nameLink: {
    textDecoration: "none"
  },
  name: {
    marginTop: "15px",
    color: "navy"
  }
}));

function ProfileFollowing(props) {
  
  const classes = useStyles();

  const appState = useContext(StateContext);
  const { username } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    
    async function fetchPosts() {
      try {
        
         let apiName = "mqsAPI";
         let apiPath = "/following";
         let apiOptions = {
           headers: {
             Authorization: `Bearer ${(await Auth.currentSession())
               .getIdToken()
               .getJwtToken()}`
           },
           queryStringParameters: {
             username: username
           }
         };
         await API.get(apiName, apiPath, apiOptions)
           .then(response => {
             setPosts(response);
             setIsLoading(false);
           })
           .catch(console.error);
      } catch (e) {
        console.log("There was a problem.", e);
      }
    }
    fetchPosts();
  }, [username]);

  if (isLoading) return (<div><Loading/></div>);


  return (
    <Container>
      {posts.length > 0 &&
        posts.map((follow, index) => {
          return (
            <Link className={classes.nameLink} key={index} to={`/profile/${follow.following}`}>
            <div className={classes.avatarDiv}>
              <img className={classes.avatar}
                src={
                  "https://mqsavatars.s3-ap-southeast-2.amazonaws.com/" +
                  follow.following +
                  ".jpg"
                }
                onError={e => {
                  e.target.src =
                    "https://myrandompictures.s3-ap-southeast-2.amazonaws.com/green.jpg";
                }}
              />{" "}
              <Typography className={classes.name} variant="h5">{follow.following}</Typography>
              </div>
            </Link>
          );
        })}
      {posts.length == 0 && appState.user.username == username && (
        <Typography variant="h5">
          You aren&rsquo;t following anyone yet.
        </Typography>
      )}
      {posts.length == 0 && appState.user.username != username && (
        <Typography variant="h5">
          {username} isn&rsquo;t following anyone yet.
        </Typography>
      )}
    </Container>
  );
}

export default ProfileFollowing;
