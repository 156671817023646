import React, { useEffect, Suspense} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import StateContext from "./StateContext";
import DispatchContext from "./DispatchContext";
import { useImmerReducer } from "use-immer";
//components
import Header from "./components/Header";
import Home from "./components/Home";
import Blog from "./components/Blog";
import BlogLoggedIn from "./components/BlogLoggedIn";
import Footer from "./components/Footer";
import About from "./components/About";
import Terms from "./components/Terms";
import Meditation from "./components/Meditation";
import Qigong from "./components/Qigong";
import Acupuncture from "./components/Acupuncture";
import Yoga from "./components/Yoga";
import SignUp from "./components/SignUp";
import Profile from "./components/Profile";
import CreatePost from "./components/CreatePost";
import ViewSinglePost from "./components/ViewSinglePost";
import EditPost from "./components/EditPost";
import FlashMessages from "./components/FlashMessages";
import Search from "./components/Search";
import AddAvatar from "./components/AddAvatar";
import Loading from "./components/Loading";
import css from "./main.css";
//authorization
import {Amplify, Auth, API} from "aws-amplify";
import config from "../config";

Amplify.configure({
  Auth: {
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    region: "ap-southeast-2",
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.USER_POOL_WEB_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "mqsAPI",
        endpoint: config.apiGateway.API_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          };
        }
      }
    ]
  }
});


function Main() {

  const initialState = {
    loggedIn: Boolean(localStorage.getItem("mqsToken")),
    signedUp: false,
    flashMessages: [],
    user: {
      token: localStorage.getItem("mqsToken"),
      username: localStorage.getItem("mqsUsername")
    },
    avatarURL:
      "https://myrandompictures.s3-ap-southeast-2.amazonaws.com/green.jpg",
    isSearchOpen: false
  };


  function myReducer(draft, action) {
    switch (action.type) {
      case "login":
        draft.loggedIn = true;
        draft.user = action.data;
        return;
      case "signup":
        draft.signedUp = action.value;
        return;
     case "avatar":
       draft.avatarURL = action.value;
        return;
      case "logout":
        draft.loggedIn = false;
        return;
      case "flashMessage":
        draft.flashMessages.push(action.value);
        return;
      case "openSearch":
        draft.isSearchOpen = true;
        return;
      case "closeSearch":
        draft.isSearchOpen = false;
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(myReducer, initialState);

  useEffect(() => {
    if (state.loggedIn) {
      localStorage.setItem("mqsToken", state.user.token);
      localStorage.setItem("mqsUsername", state.user.username);
    } else {
      localStorage.removeItem("mqsToken");
      localStorage.removeItem("mqsUsername");
    }
  }, [state.loggedIn]);

  useEffect(() => {
    if(state.loggedIn){
      async function fetchResult(){
        try{
      const api_name = "mqsAPI";
      const api_path = "/notes";
      let apiOptions = {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`
        },
        queryStringParameters: { username: state.user.username }
      };
      const promise = await API.get(api_name, api_path, apiOptions)
               .then(response =>{
                 if (!response.usr){
                   dispatch({type: "logout"});
                   dispatch({type: "flashMessage", value: "Your session has expired. Please, log in again"});
                 }
               })
               .catch(err => console.log(err));
   
        }catch(err){
          console.log(err);
        }
      }
      fetchResult();
    }
  }, []);

  useEffect(() => {
    let srcImg = "https://mqsavatars.s3-ap-southeast-2.amazonaws.com/" + state.user.username + ".jpg";
   dispatch({type: "avatar", value: srcImg});
  }, [state.user.username])

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <BrowserRouter>
          <FlashMessages messages={state.flashMessages} />
          <Header />
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/profile/:username">
                <Profile />
              </Route>
              <Route path="/create-post">
                <CreatePost />
              </Route>
              <Route path="/post/:postID" exact>
                <ViewSinglePost />
              </Route>
              <Route path="/post/:postID/edit" exact>
                <EditPost />
              </Route>
              <Route path="/blog" exact>
                {state.loggedIn ? <BlogLoggedIn /> : <Blog />}
              </Route>
              <Route path="/about-us">
                <About />
              </Route>
              <Route path="/terms">
                <Terms />
              </Route>
              <Route path="/meditation">
                <Meditation />
              </Route>
              <Route path="/qigong">
                <Qigong />
              </Route>
              <Route path="/acupuncture">
                <Acupuncture />
              </Route>
              <Route path="/yoga">
                <Yoga />
              </Route>
              <Route path="/sign-up">
                <SignUp />
              </Route>
              <Route path="/add-avatar">
                <AddAvatar />
              </Route>
              <Route path="/search">
                <Search />
              </Route>
            </Switch>
          </Suspense>
          <Footer />
        </BrowserRouter>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

ReactDOM.render(<Main />, document.querySelector("#app"));
if (module.hot) {
  module.hot.accept();
}
