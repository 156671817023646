import React, { useState, useContext } from "react";
import Avatar from "react-avatar-edit";
import {Typography, Button} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Page from "./Page";
import StateContext from "../StateContext";
import {Auth, API} from "aws-amplify";

const useStyles = makeStyles(theme => ({
  mainDiv: {
    width: "300px",
    height: "70vh",
    margin: "50px auto"
  },
  title: {
    color: "navy"
  },
  comment: {
   padding: "20px 0"
  },
  saveBtn: {
    width: "100px",
    margin: "10px 75px"
  }
}));

function AddAvatar() {

  const classes = useStyles();

  const [preview, setPreview] = useState(null);
  const appState = useContext(StateContext);

  function onClose() {
    setPreview(null);
  }

  function onCrop(pv) {
    setPreview(pv);
    async function uploadAvatar() {
      try {
        let apiName = "mqsAPI";
        let apiPath = "/avatars";
        let apiOptions = {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          },
          body: {
            user_id: appState.user.username,
            avatar: pv
          }
        };
        await API.post(apiName, apiPath, apiOptions);
      } catch (err) {
        console.log("There was an error.", err);
      }
    }
    uploadAvatar();
  }

  function onBeforeFileLoad(e) {
    if (e.target.files[0].size > 200000) {
      alert("File is too large!");
      e.target.value = "";
    } 
  }

  return (
    <Page title="Avatar Upload">
      <div className={classes.mainDiv}>
        <Typography variant="h4" className={classes.title}>
          Avatar Upload
        </Typography>
        <Typography className={classes.comment}>
          The file should be in JPG format and smaller than 200 Kb.
        </Typography>
        <div className={classes.avatarDiv}>
          <Avatar
            width={250}
            height={300}
            onCrop={onCrop}
            onClose={onClose}
            onBeforeFileLoad={onBeforeFileLoad}
            src={null}
          />
          <br />
          {preview && <img src={preview} alt="preview" />}
        </div>
        <div>
          <Button
            className={classes.saveBtn}
            variant="contained"
            color="primary"
            href="/blog"
          >
            Save
          </Button>
        </div>
      </div>
    </Page>
  );
}

export default AddAvatar;
