import React, { useEffect, useState, useContext } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link, useParams, withRouter } from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import ReactMarkdown from "react-markdown";
import Page from "./Page";
import { API, Auth } from "aws-amplify";
import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";
import NotFound from "./NotFound";
import Container from "@material-ui/core/Container";
import Loading from "./Loading";


const tooltipStyles = {
  tooltip: {
    backgroundColor: "#ebebfa",
    fontSize: "12px",
    color: "#34344c",
    letterSpacing: "1px",
    border: "1px solid #34344c"
  }
};

const StyledTooltip = withStyles(tooltipStyles)(Tooltip);

const useStyles = makeStyles(theme => ({
  paper: {
    margin: "40px auto",
    width: "75%",
    minHeight: "75vh"
  },
  flex: {
    display: "flex",
    marginLeft: "40px"
  },
  title: {
    margin: "40px 20px 0 40px"
  },
  iconDiv: {
    margin: "50px 30px 40px 30px"
  },
  fab: {
    marginRight: "10px"
  },
  avatar: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    marginLeft: "40px"
  },
  author: {
    margin: "15px 20px",
    color: "#47476b",
    fontWeight: "400"
  },
  authorLink: {
    color: "#47476b"
  },
  markdown: {
    height: "45vh",
    fontSize: "18px",
    margin: "40px 60px"
  }
}));

function ViewSinglePost(props) {
     const classes = useStyles();
     const appState = useContext(StateContext);
     const appDispatch = useContext(DispatchContext);
     const { postID } = useParams();
     const [isLoading, setIsLoading]=useState(true);
     const [post, setPost]=useState();

  useEffect(() => {
    async function fetchPost(){
      try{
       let apiName = "mqsAPI";
       let apiPath = `/note/n/${postID}`;
       let apiOptions = {
         headers: {
           Authorization: `Bearer ${(await Auth.currentSession())
             .getIdToken()
             .getJwtToken()}`
         },
         "pathParameters": {postID: postID}
       };
       
       await API.get(apiName, apiPath, apiOptions)
         .then(response => {
          setPost(response.Items[0]);
           setIsLoading(false);
         })
         .catch("There was a request error ", console.error);   
      }catch(err){
        console.log("There was an error or the request was cancelled", err)
      }
    }
    fetchPost();
  }, [postID]);

     if(!isLoading && !post){
       return <NotFound/>
     };

     if (isLoading) return (
       <Page title="...">
         <div>
           <Loading/>
         </div>
       </Page>
     );

    const date = new Date(post.timestamp*1000);
    const dateFormatted = `${date.getDate()}/${
      date.getMonth() + 1}/${date.getFullYear()}`;

   function isOwner() {
     if (appState.loggedIn) {
       return appState.user.username == post.username;
     }
     return false;
   }
   
     async function deleteHandler() {
       const confirmation = window.confirm(
         "Do you really want to delete this post?"
       );
       if (confirmation) {
         try {
           let apiName="mqsAPI";
           let apiPath = `/note/n/${postID}`;
           let apiOptions = {
             headers: {
               Authorization: `Bearer ${(await Auth.currentSession())
                 .getIdToken()
                 .getJwtToken()}`
             },
             pathParameters: { postID: post.postID },
             body: { timestamp: post.timestamp }
           };
          await API.del(apiName, apiPath, apiOptions);
             appDispatch({
               type: "flashMessage",
               value: "Post was successfully deleted."
             });
             props.history.push(`/profile/${appState.user.username}`);
         } catch (e) {
           console.log("There was a problem.");
         }
       }
     }

  return (
    <Page title={post.title}>
      <Paper elevation={3} className={classes.paper}>
        <div className={classes.flex}>
          <Typography variant="h3" className={classes.title}>
            {" "}
            {post.title}
          </Typography>
          {isOwner() && (
            <div className={classes.iconDiv}>
              <StyledTooltip title="Edit" hover>
                <Link
                  className={classes.iconLink}
                  to={`/post/${post.postID}/edit`}
                  data-tip="Edit"
                  data-for="edit"
                >
                  <Fab
                    className={classes.fab}
                    color="primary"
                    size="small"
                    aria-label="edit"
                  >
                    <EditIcon />
                  </Fab>
                </Link>
              </StyledTooltip>

              <StyledTooltip title="Delete" hover>
                <a
                  className={classes.iconLink}
                  data-tip="Delete"
                  data-for="delete"
                  onClick={deleteHandler}
                >
                  <Fab
                    className={classes.fab}
                    color="secondary"
                    size="small"
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </Fab>
                </a>
              </StyledTooltip>
            </div>
          )}
        </div>
        <div className={classes.flex}>
          <Link to={`/profile/${post.username}`}>
            <img
              className={classes.avatar}
              alt="avatar"
              src={appState.avatarURL}
              onError={e => {
                e.target.src =
                  "https://myrandompictures.s3-ap-southeast-2.amazonaws.com/green.jpg";
              }}
            />
          </Link>
          <Typography className={classes.author} variant="h6">
            Posted by{" "}
            <Link
              className={classes.authorLink}
              to={`/profile/${post.username}`}
            >
              <strong>{post.username}</strong>
            </Link>{" "}
            on <strong>{dateFormatted}</strong>.
          </Typography>
        </div>
        <div className={classes.markdown}>
          <Container>
            <ReactMarkdown
              source={post.post}
              allowedTypes={[
                "paragraph",
                "strong",
                "emphasis",
                "text",
                "heading",
                "list",
                "listItem"
              ]}
            />
          </Container>
        </div>
      </Paper>
    </Page>
  );
}

export default withRouter(ViewSinglePost)