export default {
  apiGateway: {
    API_ENDPOINT:
      "https://vy5t21jmn3.execute-api.ap-southeast-2.amazonaws.com/prod"
  },
  cognito: {
    IDENTITY_POOL_ID: "ap-southeast-2:31170916-0ea9-4cf5-b211-f9af84c4af0c",
    USER_POOL_ID: "ap-southeast-2_mGYP9YVta",
    USER_POOL_WEB_CLIENT_ID: "5s1euf7lok2v5e74aoire1756a"
  },
  s3: {
    BUCKET: "mqsAvatars"
  }
};
