import React, { useState, useContext, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import CancelIcon from "@material-ui/icons/Cancel";
import DispatchContext from "../DispatchContext";
import { useImmerReducer } from "use-immer";
import { CSSTransition } from "react-transition-group";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const useStyles = makeStyles(theme => ({
  root: {
    height: "90vh"
  },
  image: {
    backgroundImage:
      "url(https://myrandompictures.s3-ap-southeast-2.amazonaws.com/tree-swing.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "90vh",
    width: "auto"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "70vh"
  },
  lockIcon: {
    margin: "10px",
    color: "white",
    padding: "5px",
    fontSize: "35px",
    borderRadius: "50px",
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  cancelIcon: {
    margin: "10px",
    fontSize: "25px",
    color: "grey",
    position: "absolute",
    right: "10px"
  },
  liveValidateMessage: {
    fontSize: "16px",
    color: "#cc043a"
  },
  getCodeBtn: {
    backgroundColor: "#db025d",
    color: "white",
    marginTop: "20px",
    "&:hover": {
      backgroundColor: "#1c54b2"
    }
  },
  uploadAvatarBtn: {
    float: "right",
    marginTop: "20px"
  },
  avatarIcon: {
    marginRight: "5px"
  }
}));

export default function SignUp(props) {
  const classes = useStyles();
  const appDispatch = useContext(DispatchContext);

  //Input Validation
  const initialState = {
    username: {
      value: "",
      hasErrors: false,
      message: ""
    },
    email: {
      value: "",
      hasErrors: false,
      message: ""
    },
    password: {
      value: "",
      hasErrors: false,
      message: ""
    },
    avatarURL: "",
    submitCount: 0
  };

  function myReducer(draft, action) {
    switch (action.type) {
      case "avatar":
        draft.avatarURL = action.data;
        return;
      case "usernameImmediately":
        draft.username.hasErrors = false;
        draft.username.value = action.value;
        if (draft.username.value.length > 30) {
          draft.username.hasErrors = true;
          draft.username.message = "Username cannot exceed 30 characters.";
        }
        if (
          draft.username.value &&
          !/^([a-zA-Z0-9]+)$/.test(draft.username.value)
        ) {
          draft.username.hasErrors = true;
          draft.username.message =
            "Username can only contain letters and numbers.";
        }
        return;
      case "usernameAfterDelay":
        if (draft.username.value.length < 3) {
          draft.username.hasErrors = true;
          draft.username.message = "Username must be at least 3 characters.";
        }
        return;
      case "emailImmediately":
        draft.email.hasErrors = false;
        draft.email.value = action.value;
        return;
      case "emailAfterDelay":
        if (!/^\S+@\S+$/.test(draft.email.value)) {
          draft.email.hasErrors = true;
          draft.email.message = "You must provide a valid email address.";
        }
        return;
      case "passwordImmediately":
        draft.password.hasErrors = false;
        draft.password.value = action.value;
        if (draft.password.value.length > 50) {
          draft.password.hasErrors = true;
          draft.password.message = "Password cannot exceed 50 characters.";
        }
        return;
      case "passwordAfterDelay":
        if (draft.password.value.length < 8) {
          draft.password.hasErrors = true;
          draft.password.message = "Password must be at least 8 characters.";
        }
        return;
      case "submitForm":
        if (
          !draft.username.hasErrors &&
          !draft.email.hasErrors &&
          !draft.password.hasErrors
        ) {
          draft.submitCount++;
        }
        return;
    }
  }

  const [state, dispatch] = useImmerReducer(myReducer, initialState);

  useEffect(() => {
    if (state.username.value) {
      const delay = setTimeout(
        () => dispatch({ type: "usernameAfterDelay" }),
        800
      );
      return () => clearTimeout(delay);
    }
  }, [state.username.value]);

  useEffect(() => {
    if (state.email.value) {
      const delay = setTimeout(
        () => dispatch({ type: "emailAfterDelay" }),
        800
      );
      return () => clearTimeout(delay);
    }
  }, [state.email.value]);

  useEffect(() => {
    if (state.password.value) {
      const delay = setTimeout(
        () => dispatch({ type: "passwordAfterDelay" }),
        800
      );
      return () => clearTimeout(delay);
    }
  }, [state.password.value]);

  const [verificationCode, setVerificationCode] = useState();

  const [open, setOpen] = useState(false);
  const [openPP, setOpenPP] = useState(false);
  const [openTS, setOpenTS] = useState(false);

  async function handleClickOpen(e) {
    e.preventDefault();
    setOpen(true);
    try {
      let username = state.username.value;
      let password = state.password.value;
      let email = state.email.value;
      await Auth.signUp({ username, password, attributes: { email } });
    } catch (error) {
      appDispatch({
        type: "flashMessage",
        value: error.message
      });
    }
  }

  //Dialogs
  const handleClickOpenPP = () => {
    setOpenPP(true);
  };
  const handleClickOpenTS = () => {
    setOpenTS(true);
  };

  const handleClosePP = () => {
    setOpenPP(false);
  };
  const handleCloseTS = () => {
    setOpenTS(false);
  };

  function handleCloseIcon() {
    setOpen(false);
  }

  //Verification Code
  async function handleClose(e) {
    e.preventDefault();
    setOpen(false);
    try {
      await Auth.confirmSignUp(state.username.value, verificationCode);
      appDispatch({
        type: "flashMessage",
        value: "You are signed upsuccessfully."
      });
    } catch (error) {
      console.log(error);
      appDispatch({
        type: "flashMessage",
        value: error.message
      });
    }
  }

  //Sign Up
  async function handleSubmit(e) {
    e.preventDefault();
    dispatch({ type: "usernameImmediately", value: state.username.value });
    dispatch({
      type: "usernameAfterDelay",
      value: state.username.value,
      noRequest: true
    });
    dispatch({ type: "emailImmediately", value: state.email.value });
    dispatch({
      type: "emailAfterDelay",
      value: state.email.value,
      noRequest: true
    });
    dispatch({ type: "passwordImmediately", value: state.password.value });
    dispatch({ type: "passwordAfterDelay", value: state.password.value });
    dispatch({ type: "submitForm" });
    appDispatch({ type: "signup", value: true });
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <LockOutlinedIcon className={classes.lockIcon} />

          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <FormControl fullWidth margin="normal">
              <InputLabel>Username</InputLabel>
              <Input
                required
                fullWidth
                id="username"
                name="username"
                onChange={e =>
                  dispatch({
                    type: "usernameImmediately",
                    value: e.target.value
                  })
                }
              />
              <CSSTransition
                in={state.username.hasErrors}
                timeout={330}
                classNames={classes.liveValidateMessage}
                unmountOnExit
              >
                <div className={classes.liveValidateMessage}>
                  {state.username.message}
                </div>
              </CSSTransition>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Email Address</InputLabel>
              <Input
                required
                fullWidth
                id="email"
                name="email"
                onChange={e =>
                  dispatch({ type: "emailImmediately", value: e.target.value })
                }
              />
              <CSSTransition
                in={state.email.hasErrors}
                timeout={330}
                classNames={classes.liveValidateMessage}
                unmountOnExit
              >
                <div className={classes.liveValidateMessage}>
                  {state.email.message}
                </div>
              </CSSTransition>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Password</InputLabel>
              <Input
                required
                fullWidth
                name="password"
                type="password"
                id="password"
                onChange={e =>
                  dispatch({
                    type: "passwordImmediately",
                    value: e.target.value
                  })
                }
              />
              <CSSTransition
                in={state.password.hasErrors}
                timeout={330}
                classNames={classes.liveValidateMessage}
                unmountOnExit
              >
                <div className={classes.liveValidateMessage}>
                  {state.password.message}
                </div>
              </CSSTransition>
              <FormHelperText>
                Enter at least 8 characters including Upper case, Lower Case and
                numbers
              </FormHelperText>
            </FormControl>
            <div>
              <Button
                className={classes.getCodeBtn}
                variant="contained"
                onClick={handleClickOpen}
              >
                Verification Code
              </Button>
              <Button
                color="primary"
                className={classes.uploadAvatarBtn}
                href="/add-avatar"
              >
                <AccountCircleIcon className={classes.avatarIcon} />
                Upload Avatar
              </Button>
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign Up
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >
              <CancelIcon
                className={classes.cancelIcon}
                onClick={handleCloseIcon}
              />
              <DialogTitle id="form-dialog-title">
                Email Verification
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Verification code was sent to your email. Please, enter the
                  code here.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="verificationCode"
                  label="Verification Code"
                  type="verificationCode"
                  fullWidth
                  onChange={e => setVerificationCode(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" href="/blog">
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
            <Grid container>
              <Grid item xs>
                <Button onClick={handleClickOpenPP} color="primary">
                  Privacy Policy
                </Button>
                <Dialog
                  open={openPP}
                  onClose={handleClosePP}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <CancelIcon
                    className={classes.cancelIcon}
                    onClick={handleClosePP}
                  />
                  <DialogTitle id="alert-dialog-title">
                    {"Privacy Policy"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Your email address will be used for registration
                      confirmation only. It will be saved securely and never
                      given to any third party.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClosePP} color="primary" autoFocus>
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item>
                <Button onClick={handleClickOpenTS} color="primary">
                  Terms of Service
                </Button>
                <Dialog
                  open={openTS}
                  onClose={handleCloseTS}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <CancelIcon
                    className={classes.cancelIcon}
                    onClick={handleCloseTS}
                  />
                  <DialogTitle id="alert-dialog-title">
                    {"Terms of Service"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Here I have to write something about Terms of Service.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseTS} color="primary" autoFocus>
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
            </Grid>
            <Box mt={5}></Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
