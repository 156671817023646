import React, { useEffect, useContext } from "react";
import DispatchContext from "../DispatchContext";
import { useImmer } from "use-immer";
import { API, Auth } from "aws-amplify";
import Post from "./Post";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
  paper: {
    margin: "50px auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "75vw",
    minHeight: "60vh",
    padding: "30px"
  },
  inputDiv: {
    display: "flex"
  },
  searchLabel: {
    fontSize: "22px",
    color: "navy"
  },
  input: {
    marginLeft: "15px",
    width: "20%"
  },
  searchResults: {
    margin: "40px 0",
    width: "78%"
  },
  searchResultsHeader: {
    color: "navy",
    margin: "20px 5px",
    fontSize: "18px"
  },
  notFoundMessage: {
    margin: "5px auto"
  },
  notFoundMessageHide: {
    display: "none"
  },
  monkey: {
    width: "45vw",
    height: "auto",
    marginTop: "50px",
    borderRadius: "20px",
    float: "right"
  }
}));

function Search(props) {
  const classes = useStyles();
  const appDispatch = useContext(DispatchContext);

  const [state, setState] = useImmer({
    searchTerm: "",
    results: [],
    show: "neither",
    requestCount: 0
  });

  useEffect(() => {
    document.addEventListener("keyup", searchKeyPressHandler);
    return () => document.removeEventListener("keyup", searchKeyPressHandler);
  }, []);

  useEffect(() => {
    if (state.searchTerm.trim()) {
      setState(draft => {
        draft.show = "loading";
      });
      const delay = setTimeout(() => {
        setState(draft => {
          draft.requestCount++;
        });
      }, 750);

      return () => clearTimeout(delay);
    } else {
      setState(draft => {
        draft.show = "neither";
      });
    }
  }, [state.searchTerm]);

  useEffect(() => {
    if (state.requestCount) {

      async function fetchResults() {
        try {

                let apiName = "mqsAPI";
                let apiPath = "/note";
                let apiOptions = {
                  headers: {
                    Authorization: `Bearer ${(await Auth.currentSession())
                      .getIdToken()
                      .getJwtToken()}`
                  },
                  queryStringParameters: { words: state.searchTerm }
                };
                await API.get(apiName, apiPath, apiOptions)
                  .then(response => {
                  setState(draft => {
                    draft.results = response.Items;
                    draft.show = "results";
                  });
                  })
                  .catch(console.error);
        } catch (e) {
          console.log("There was a problem or the request was cancelled.");
        }
      }
      fetchResults();
    }
  }, [state.requestCount]);

  function searchKeyPressHandler(e) {
    if (e.keyCode == 27) {
      appDispatch({ type: "closeSearch" });
    }
  }

  function handleInput(e) {
    const value = e.target.value;
    setState(draft => {
      draft.searchTerm = value;
    });
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      <Container className={classes.inputDiv}>
        <Typography className={classes.searchLabel}>Search:</Typography>
        <input
          className={classes.input}
          onChange={handleInput}
          autoFocus
          type="text"
          autoComplete="off"
          id="live-search-field"
          placeholder="What are you interested in?"
        />
        <span onClick={() => appDispatch({ type: "closeSearch" })}></span>
      </Container>
      <div className={classes.searchResults}>
        <div
          className={
            state.show == "results" ? classes.notFoundMessage : classes.notFoundMessageHide
          }
        >
          {Boolean(state.results.length) && (
            <div>
              <Typography className={classes.searchResultsHeader}>
                <strong>Search Results</strong> ({state.results.length}{" "}
                {state.results.length > 1 ? "items" : "item"} found){" "}
              </Typography>
              {state.results.map(post => {
                return (
                  <Post
                    post={post}
                    key={post.postID}
                    onClick={() => appDispatch({ type: "closeSearch" })}
                  />
                );
              })}
            </div>
          )}
          {!Boolean(state.results.length) && (
            <div className={classes.notFoundMessage}>
              <Typography>
                Sorry, we could not find any results for that search.
              </Typography>
              <img
                alt="thinking monkey"
                src="https://myrandompictures.s3-ap-southeast-2.amazonaws.com/thinking-monkey.jpg"
                className={classes.monkey}
              />
            </div>
          )}
        </div>
      </div>
    </Paper>
  );
}

export default Search;
