import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Page from './Page';

const useStyles = makeStyles(theme => ({
  picture: {
    minHeight: "85vh",
    width: "auto",
    color: "white",
    backgroundImage:
      "url(https://myrandompictures.s3-ap-southeast-2.amazonaws.com/little-monk.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  mainTitle: {
    padding: "5% 15% 2% 5%"
  },
  aboutP: {
    width: "40%",
    fontSize: "22px",
    padding: "40px"
  }
}));

function About() {
  const classes = useStyles();
  return (
    <Page title="About">
    <div className={classes.picture}>
      <Typography variant="h3" className={classes.mainTitle}>
        ABOUT
      </Typography>
      <Typography className={classes.aboutP}>
        This website is about scientifically proven effects of meditation,
        qigong and accupuncture on our mental and physical health. To leave
        comments and use blog, you need to sign up.
      </Typography>
    </div>
    </Page>
  );
}

export default About;
