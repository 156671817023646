import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import HeaderLoggedOut from "./HeaderLoggedOut";
import HeaderLoggedIn from "./HeaderLoggedIn";
import StateContext from "../StateContext";


const useStyles = makeStyles(theme => ({
  navbar: {
    color: "white",
    background: "rgb(67, 67, 122)",
    display: "flex",
    justifyContent: "space-between",
    padding: "0 20px 0 20px"
  },
  logo: {
    fontSize: "20px",
    marginTop: "20px"
  },
  navlinks: {
    display: "inline-block",
    color: "white",
    margin: "10px",
    paddingTop: "10px",
    textDecoration: "none",
    "&:hover": {
      color: "#ffd480"
    }
  }
}));

function Header(props) {
  const classes = useStyles();

  const appState = useContext(StateContext);

  return (
    <div className={classes.navbar}>
      <div>
        <Typography className={classes.logo}>
          Meditation, Quigong and Science
        </Typography>
      </div>
      <div>
        <Link to="/" className={classes.navlinks}>
          <Typography>HOME</Typography>
        </Link>{" "}
        <Link className={classes.navlinks} to="/about-us">
          <Typography>ABOUT</Typography>
        </Link>{" "}
        <Link className={classes.navlinks} to="/blog">
          <Typography>BLOG</Typography>
        </Link>
        <Link className={classes.navlinks} to="/terms">
          <Typography>TERMS</Typography>
        </Link>
      </div>
      <div>
        {appState.loggedIn ? (<HeaderLoggedIn />) : (<HeaderLoggedOut />)}
      </div>
    </div>
  );
}

export default Header;
