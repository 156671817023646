import React, { useEffect, useContext } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StateContext from "../StateContext";
import Page from "./Page";
import Post from "./Post";
import { useImmerReducer } from "use-immer";
import { API, Auth } from "aws-amplify";
import Loading from "./Loading";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "85vh"
  },
  image: {
    backgroundImage:
      "url(https://myrandompictures.s3-ap-southeast-2.amazonaws.com/feng-shui.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  postDiv: {
    width: "80%"
  },
  hello: {
    marginBottom: "50px"
  },
  uploadAvatar: {
    margin: "20px",
    letterSpacing: "2px",
    fontSize: "12px"
  },
  avatar: {
    width: "100px",
    height: "100px",
    marginBottom: "40px"
  }
}));

function BlogLoggedIn(props) {
  const classes = useStyles();
  const appState = useContext(StateContext);

  function myReducer (draft, action){
   switch (action.type){
     case "loading":
       draft.isLoading = action.value;
       return;
     case "feed":
       draft.feed = action.value;
       return;
     case "following":
       draft.following = action.value;
       return;
   }
  };
  
  let initialState = {
    isLoading: true,
    feed: [],
    following: []
  };
  const [state, dispatch] = useImmerReducer(myReducer, initialState);

  let apiName = "mqsAPI";
  let apiPath1 = "/test";
  let apiPath2 = "/following";

  //get following
  useEffect(() => {
    async function fetchData() {
      try {
        let apiOptions = {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          },
          queryStringParameters: { username: appState.user.username }
        };

        await API.get(apiName, apiPath2, apiOptions)
          .then(response => { 
            let followingList = "";
                 response.forEach(
                   member =>
                     (followingList = followingList + "," + member.following)
                 );  
             
              dispatch({type: "following", value: followingList.toString()}); 
          })
          .catch(console.error);
      } catch (e) {
        console.log("There was a problem.", e);
      }
    }
    fetchData();
  }, [appState.user.username]);

  //get latest posts
  useEffect(() => {
    async function fetchData() {
      try { if (state.following){
         let apiOptions = {
           headers: {
             Authorization: `Bearer ${(await Auth.currentSession())
               .getIdToken()
               .getJwtToken()}`
           },
           queryStringParameters: { names: state.following}
         };

         await API.get(apiName, apiPath1, apiOptions)
           .then(response => {
             dispatch({type: "loading", value: false});
             dispatch({type: "feed", value: response})
           })
           .catch("There was a request error ", console.error);
      }else{
        dispatch({ type: "loading", value: false });
        dispatch({ type: "feed", value: [] });
      }
                 
      } catch (e) {
        console.log("There was a problem.", e);
      }
    }
        fetchData();
  }, [ state.following, dispatch]);

  if (state.isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <Page title="Blog">
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={2} md={5} className={classes.image} />
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          component={Paper}
          elevation={5}
          square
        >
          <Button
            className={classes.uploadAvatar}
            variant="contained"
            color="primary"
            href="/add-avatar"
          >
            Change Avatar
          </Button>
          {state.feed.length > 0 && (
            <div className={classes.paper}>
              <Typography variant="h4" className={classes.hello}>
                {" "}
                The Latest Posts From Those You Follow
              </Typography>
              <div className={classes.postDiv}>
                {state.feed.map(post => {
                  return <Post post={post} key={state.feed.postID} />;
                })}
              </div>
            </div>
          )}
          {state.feed.length == 0 && (
            <div className={classes.paper}>
              <img
                src={appState.avatarURL}
                alt="avatar"
                className={classes.avatar}
                onError={e => {
                  e.target.src =
                    "https://myrandompictures.s3-ap-southeast-2.amazonaws.com/green.jpg";
                }}
              />
              <Typography variant="h4" className={classes.hello}>
                Hello <strong>{appState.user.username}</strong>, your feed is
                empty.
              </Typography>
              <Typography>
                Your feed displays the latest posts from the people you follow.
                If you don&rsquo;t have any friends to follow, you can use the
                &ldquo;Search&rdquo; feature in the top menu bar to find content
                written by people with similar interests and then follow them.
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    </Page>
  );
}

export default BlogLoggedIn;
