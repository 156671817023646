import React, { useEffect } from "react";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Page from "./Page";

const useStyles = makeStyles(theme => ({
  mainTitleDiv: {
    backgroundImage:
      "url(https://myrandompictures.s3-ap-southeast-2.amazonaws.com/thai-sunset.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "33vh",
    width: "auto"
  },
  mainTitle: {
    padding: "30px 50px",
    color: "navy"
  },
  flexDiv: {
    display: "flex"
  },
  textDiv: {
    width: "75%",
    padding: "5px 40px"
  },
  referencesDiv: {
    width: "25%"
  },
  btn: {
    width: "180px",
    margin: "10px",
    float: "right"
  }
}));

function Acupuncture() {
  const classes = useStyles();
  return (
    <Page title="Meditation">
      <div className={classes.mainTitleDiv}></div>
      <div>
        <Button
          color="primary"
          variant="contained"
          className={classes.btn}
          href="/yoga"
        >
          Yoga
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.btn}
          href="/qigong"
        >
          Qigong
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.btn}
          href="/meditation"
        >
          Meditation
        </Button>
      </div>
      <Typography variant="h3" className={classes.mainTitle}>
        {" "}
        Acupuncture{" "}
      </Typography>
      <div className={classes.flexDiv}>
        <div className={classes.textDiv}>
          <p> text about acupuncture</p>
        </div>
        <div className={classes.referencesDiv}>
          <Typography variant="h5">References</Typography>
        </div>
      </div>
    </Page>
  );
}

export default Acupuncture;
