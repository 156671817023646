import React, {useState, useContext} from "react";

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import Page from './Page';
import { Auth} from "aws-amplify";
import DispatchContext from "../DispatchContext";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "90vh"
  },
  image: {
    backgroundImage:
      "url(https://myrandompictures.s3-ap-southeast-2.amazonaws.com/three-yogis.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "90vh",
    width: "auto"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  cancelIcon: {
  margin: "10px",
  fontSize: "25px",
  color: "grey",
  position: "absolute",
  right: "10px"
  },
  dialogBtn: {
    margin: "20px 10px 10px 0"
  }
}));

export default function Blog(props) {
  const classes = useStyles();

  const appDispatch = useContext(DispatchContext);

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [verificationCode, setVerificationCode] = useState();
  let response={};

   const [open, setOpen] = useState(false);

   const handleClickOpen = () => {
     setOpen(true);
   };

   async function handleClose(e) {
     e.preventDefault();
     setOpen(false);
     try {
       await Auth.forgotPasswordSubmit(username, verificationCode, newPassword);
         appDispatch({
           type: "flashMessage",
           value: "Your password was updated successfully."
         });
       
     } catch (error) {
         appDispatch({
           type: "flashMessage",
           value: "There was an error while submitting a verification code."
         });
     }
   }

   function handleCloseIcon(){
     setOpen(false);
   }

     async function handleNewPassword(e) {
       e.preventDefault();
       try {
         await Auth.forgotPassword(username);
           appDispatch({
             type: "flashMessage",
             value: "Please, enter your verification code."
           });
       } catch (error) {
           appDispatch({
             type: "flashMessage",
             value: "There was an error while submitting a verification code."
           });
       }
     }

  async function handleSubmit(e){
    e.preventDefault();
    try{
   await Auth.signIn(username, password);

   await Auth.currentSession()
    .then(data => {let token = data.idToken.jwtToken;
                   let username = data.accessToken.payload.username;
                   response = { token: token, username: username };
                  })
    .catch(err => console.log(err)); 
     appDispatch({ type: "login", data: response });
     appDispatch({
       type: "flashMessage",
       value: "You have successfully logged in."
     });
    }catch(err){
       appDispatch({
         type: "flashMessage",
         value: "Invalid username or password."
       });
    }
  }

  return (
    <Page title="Sign In">
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign In
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                onChange={e => setUsername(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e => setPassword(e.target.value)}
              />
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Button color="primary" onClick={handleClickOpen}>
                    Forgot password?
                  </Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                  >
                    <CancelIcon
                      className={classes.cancelIcon}
                      onClick={handleCloseIcon}
                    />
                    <DialogTitle id="form-dialog-title">
                      Renew Password
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Verification code will be sent to your email after you
                        submit your username and new password.
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="username"
                        label="Username"
                        type="username"
                        fullWidth
                        onChange={e => setUsername(e.target.value)}
                      />
                      <TextField
                        autoFocus
                        margin="dense"
                        id="newPassword"
                        label="New Password"
                        type="newPassword"
                        fullWidth
                        onChange={e => setNewPassword(e.target.value)}
                      />
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleNewPassword}
                        className={classes.dialogBtn}
                      >
                        Submit New Password
                      </Button>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="verificationCode"
                        label="Verification Code"
                        type="verificationCode"
                        fullWidth
                        onChange={e => setVerificationCode(e.target.value)}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        color="primary"
                        variant="contained"
                        href="/blog"
                        className={classes.dialogBtn}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item>
                  <Button href="/sign-up" color="primary">
                    Don't have an account? Sign Up
                  </Button>
                </Grid>
              </Grid>
              <Box mt={5}></Box>
            </form>
          </div>
        </Grid>
      </Grid>
    </Page>
  );
}
