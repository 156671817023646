import React, {useContext, useEffect} from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Typography } from "@material-ui/core";
import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: "none"
  },
  listItem: {
    margin: "7px 0",
    border: "1px solid #c1c1d7",
    borderRadius: "3px"
  },
  avatar: {
    height: "40px",
    width: "40px",
    borderRadius: "50%"
  },
  postTitle: {
    color: "#47476b"
  },
  byWho: {
    margin: "7px",
    paddingTop: "3px",
    color: "#3d3d5c"
  },
  when: {
    paddingTop: "3px",
    color: "#7575a3"
  }
}));

function Post(props) {
  const classes = useStyles();
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);

  const post = props.post;
  const date = new Date(post.timestamp * 1000);
  const dateFormatted = `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()}`;
  let imageSrc = "https://mqsavatars.s3-ap-southeast-2.amazonaws.com/" + post.username + ".jpg";

  return (
    <Link
      className={classes.link}
      onClick={props.onClick}
      to={`/post/${post.postID}`}
    >
      <ListItem className={classes.listItem}>
        <ListItemAvatar>
          <img
            className={classes.avatar}
            src={imageSrc}
            onError={(e) => {e.target.src =
              "https://myrandompictures.s3-ap-southeast-2.amazonaws.com/green.jpg"}} 
          />
        </ListItemAvatar>
        <Typography className={classes.postTitle} variant="h6">
          {post.title}
        </Typography>
        <Typography className={classes.byWho}>
          {" "}
          {!props.noAuthor && (
            <>
              by <strong> {post.username} </strong>
            </>
          )}
        </Typography>
        <Typography className={classes.when}> on {dateFormatted}</Typography>
      </ListItem>
    </Link>
  );
}

export default Post;
