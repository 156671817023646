import React from "react";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Page from "./Page";

const useStyles = makeStyles(theme => ({
  mainTitleDiv: {
    backgroundImage:
      "url(https://myrandompictures.s3-ap-southeast-2.amazonaws.com/thai-sunset.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "33vh",
    width: "auto"
  },
  mainTitle: {
    padding: "30px 20px",
    color: "navy"
  },
  textDiv: {
    width: "60%",
    padding: "5px 40px",
    fontSize: "18px",
    margin: "10px auto"
  },
  subtitle: {
    color: "#11449e"
  },
  h6subtitle: {
    color: "#0d7fd1"
  },
  refLink: {
    color: "navy",
    textDecoration: "none",
    "&:hover": {
      color: "#258510"
    }
  },
  btn: {
    width: "180px",
    margin: "10px",
    float: "right"
  }
}));

function Meditation() {
 const classes = useStyles();
  return (
    <Page title="Meditation">
      <div className={classes.mainTitleDiv}></div>
      <div>
        <Button
          color="primary"
          variant="contained"
          className={classes.btn}
          href="/yoga"
        >
          Yoga
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.btn}
          href="/accupuncture"
        >
          Acupuncture
        </Button>
        <Button
          color="primary"
          variant="contained"
          className={classes.btn}
          href="/qigong"
        >
          Qigong
        </Button>
      </div>
      <Typography variant="h3" className={classes.mainTitle}>
        {" "}
        Meditation{" "}
      </Typography>
      <div className={classes.textDiv}>
        <Typography variant="h5" className={classes.subtitle}>
          Improved Health and Well-being
        </Typography>
        <p>
          Two main benefits of meditation are stress reduction and increased
         self-awareness. To understand how meditation improves our health, 
         let us first consider how stress affects it.
        </p>
        <Typography variant="h6" className={classes.h6subtitle}>
          Stress
        </Typography>
        <p>
          Our bodies were fine-tuned during millions years of evolution to
          preserve an optimal steady state that allows us to adapt to
          ever-changing stressful environment. A stress system was developed to
          increase our chanses of survival. This system has a complex
          interconnected neuroendorine, cellular and molecular infrastructure.
          Its main task is to trigger a cluster of time-limited behavioral and
          physical changes, and activate restraining forces to avoid excessive
          stress response [1]. The stress system redirects energy, nutrients and
          oxygen to the areas that are the most important for our survival,
          while less important functions such asdigestion, reproduction, growth
          and immunity are temporally suppressed.
          <br />
          <br />
          Behavioral adaptation includes [1]:
          <ul>
            <li>enhanced arousal and allertness</li>
            <li>increased cognition, vigilance and focused attention</li>
            <li>suppressed appetite</li>
            <li>suppressed reproductive behavior</li>
            <li>inhibited gastric motility</li>
            <li>increased colonic activity</li>
          </ul>
          <br />
          Physical adaptation includes [1]:
          <ul>
            <li>adaptive redirection of energy</li>
            <li>
              redirection of nutrients and oxygen to the central nervous system
              and stressed body sites
            </li>
            <li>altered cardiovascular tone</li>
            <li>increased blood pressure and heart rate</li>
            <li>increased respiratory rate</li>
            <li>increased glucogenesis and lipolysis</li>
            <li>detoxification from toxic products</li>
            <li>inhibition of growth</li>
            <li>inhibition of reproductive functions</li>
            <li>containment of the inflammatory and immune response</li>
          </ul>
          <br />
          When the stress response is inadequate or excessive and/or the stress is
          highly potent or prolonged, the body cannot return to its optimal
          state and disease is developed. Knowing how stress adaptation works,
          it is easy to predict what type of the disease may develop.
        </p>
        <p>
          Sahaja Yoga Meditation has been shown to have positive effects on a
          range of physical, mental and neurological disorders including asthma,
          high blood pressure, menopause, epilepsy, depression, anxiety, work
          stress and deficit/hyperactivity disorder [2]. Transcedental
          Meditation helps to improve self-control and reduce blood pressure,
          cholesterol levels, lipid oxidation, stress and anxiety [3].
        </p>
        <Typography variant="h6" className={classes.h6subtitle}>
          Self-awareness
        </Typography>
        <Typography variant="h5" className={classes.subtitle}>
          Meditation and Longevity
        </Typography>
        <p>Prolonged life</p>
        <Typography variant="h5" className={classes.subtitle}>
          Meditation and Brain Structure
        </Typography>
        <p>
          There are many types of meditation techniques, but all of them work
          with attention and mental focus. Based on the object of the focus, the
          meditation techniques can be divided in two main groups [4]:
          <ul>
            <li>focused attention</li>
            <li>open monitoring.</li>
          </ul>
          During the focused attention (FA) meditation, the meditator
          concentrates attention on a selected object and atempts to avoid
          distracting thoughts. The object of the focus can be a breath, body
          sensation, sound, emotion, image or God. This type of meditation
          includes Himalayan Yoga, Mantra, loving kindness, transcedental
          meditation, and spiritual meditation.
          <br />
          <br />
          The open monitoring (OM) techniques are focused on awareness itself.
          The meditator allows any thoughts and sensations to arise and
          disappear like sea waves. He or she is just observing them without any
          paticular attachment or focus. OM techniques include mindfulness, Zen,
          Isha Yoga, Shoonya Yoga and Vipassana.
          <br />
          <br />
          Different types of meditation activate different regions of the brain
          [5].
        </p>
        <Typography className={classes.subtitle} variant="h5">
          References
        </Typography>
        <ol>
          <li>
            C. Tsigos, T. Kyrou, F. Kassi, G. Chrousos.{" "}
            <a
              className={classes.refLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ncbi.nlm.nih.gov/books/NBK278995"
            >
              Stress: Endocrine Physiology and Pathophysiology.
            </a>{" "}
            Endotext, 2020.
          </li>
          <li>
            S. Hernandez, J. Suero, A. Barros, J. Gonzalez-Mora, K. Rubia.{" "}
            <a
              className={classes.refLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0150757"
            >
              Increased grey matter associated with long-term Sahaja yoga
              meditation: A voxel-based morphometry study.
            </a>{" "}
            PLOS ONE, 2016, doi: 10.1371/journal.pone.0150757.
          </li>
          <li>
            K. Walton, R. Schneider, S. Nidich.{" "}
            <a
              className={classes.refLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2211376"
            >
              Review of controlled research on the transcedental meditation
              program and cardiovascular disease: risk factors, morbidity and
              mortality.
            </a>{" "}
            Cardiology Reviews, 12(2004)262-266.
          </li>
          <li>
            C. Braboszcz, S. Hahusseau, A. Delorme (2010){" "}
            <a
              className={classes.refLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://serenityworks.com.au/wp-content/uploads/2014/07/delorme_braboszcz_meditation.pdf"
            >
              Meditation and Neuroscience: from basec research to clonical
              practice.
            </a>{" "}
            In "Integrative Clinical Psychology, Psychiatry and Behavioral
            Medicine: Perspectives, Practices and Research". Editor: R.
            Carlstedt. Springer Publishing.
          </li>{" "}
          <br />
          <li>
            D. Lee, E. Kulubya, P. Goldin, A. Goodarzi, F. Girgis.{" "}
            <a
              className={classes.refLink}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5890111/"
            >
              Review of the neural oscillations underlying meditation.
            </a>{" "}
            Frontiers in NeuroScience (2018) doi: 10.3389/fnins.2018.00178
          </li>
          <br />
        </ol>
      </div>
    </Page>
  );
}

export default Meditation
