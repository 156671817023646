import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import StateContext from "../StateContext";
import Post from "./Post";
import { API, Auth } from "aws-amplify";
import Loading from "./Loading";


function ProfilePosts(props) {

  const appState = useContext(StateContext);
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const { username } = useParams();
  

  useEffect(() => {
    async function fetchPosts() {
      try {
        let apiName = "mqsAPI";
        let apiPath = "/notes";
        let apiOptions = {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`
          },
          'queryStringParameters': {username: username}
        };
        await API.get(apiName, apiPath, apiOptions)
          .then(response => {
            setPosts(response.data_p.Items);  
            setIsLoading(false);
          })
          .catch(console.error);
      } catch (err) {
        console.log("There was an API error", err);
      }
    }
    fetchPosts();
    
  }, [username]);

  if (isLoading)
    return (
      <div>
       <Loading/>
      </div>
    );

  return (
    <div>
        {posts.length > 0 &&
          posts.map(post => {
            return <Post noAuthor={true} post={post} key={post.postID} />;
          })}
        {posts.length == 0 && (
          <Typography variant="h5">
            There are no posts yet.
          </Typography>
        )}
    </div>
  );
}

export default ProfilePosts;
