import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Typography, Button }from "@material-ui/core";
import { Link } from "react-router-dom";

function Category(props) {

let sectionId=`${props.sectionID}`;
let imageSrc="";
let section_title="";
let introduction="";
let category_page="";

switch (sectionId){
    case "meditation":
      imageSrc = "https://myrandompictures.s3-ap-southeast-2.amazonaws.com/meditating-monk.jpg";
      section_title = "Meditation";
      introduction =
        "Many people may imagine a buddhist monk or a yogi siting in the lotus position and chanting 'OM', when they hear the word meditation. If we forget about its religious origin, meditation can be considered as a very effective mental exercise allowing us to improve attention, reduce stress and regulate emotions. During the meditation, attention is focused on a selected object (breath, body sensation, sound, mental image, emotion or idea) or on awareness itself. Scientific studies of meditation are mainly focused on the effects of meditation on mental and physical health as well as brain structure, brain waves, and longevity. The ability of meditation to trigger altered states of consciousness is actively used in consciousness research.";
      category_page = "/meditation";
      break;
    case "qigong":
      imageSrc = "https://myrandompictures.s3-ap-southeast-2.amazonaws.com/lotus-vertical.jpg";
      section_title = "Qigong";
      introduction = "Paragraph about qigong";
      category_page = "/qigong";
      break;
    case "acupuncture":
      imageSrc = "https://myrandompictures.s3-ap-southeast-2.amazonaws.com/feng-shui.jpg";
      section_title = "Acupuncture";
      introduction = "Paragraph about acupuncture";
      category_page = "/acupuncture";
      break;
    case "yoga":
      imageSrc = "https://myrandompictures.s3-ap-southeast-2.amazonaws.com/lotus.jpg";
      section_title = "Yoga";
      introduction = "Paragraph about yoga";
      category_page = "/yoga";
      break;
}

    const useStyles = makeStyles(theme => ({
      section: {
        display: "flex",
        backgroundColor: "white"
      },
      image: {
        height: "70vh",
        width: "auto",
        borderRadius: "5px",
        margin: "5px 0"
      },
      textDiv: {
        marginLeft: "50px",
        borderBottom: "2px solid #c3cfde",
        width: "100%"
      },
      sectionTitle: {
        color: "navy",
        fontSize: "45px",
        margitTop: "30px"
      },
      p: {
        margin: "30px 0",
        fontSize: "18px"
      },
      btnLink: {
        textDecoration: "none",
        color: "#244591"
      }
    }));
     const classes = useStyles();
  return (
    <div>
      <div className={classes.section} id={props.sectionID}>
        <div className={classes.imageDiv}>
          <img className={classes.image} alt="Meditating monk" src={imageSrc} />
        </div>
        <div className={classes.textDiv}>
          <Typography className={classes.sectionTitle}>
            {section_title}
          </Typography>
          <Typography className={classes.p}>{introduction}</Typography>
          <Button variant="outlined" color="primary">
            <Link className={classes.btnLink} to={category_page}>Read More &raquo;</Link>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Category;