import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import { Auth} from "aws-amplify";
import DispatchContext from "../DispatchContext";
import StateContext from "../StateContext";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

const StyledButton2 = withStyles({
  root: {
    color: "white",
    height: 45,
    width: 120,
    padding: "10px",
    margin: "10px",
    border: "1px solid white",
    "&:hover": {
      border: "2px solid white",
      backgroundColor: "#b30059"
    }
  },
  label: {
    letterSpacing: "3px",
    fontSize: "12px",
    "&:hover": {
      fontSize: "13px"
    }
  }
})(Button);

const tooltipStyles = {
  tooltip: {
    backgroundColor: "#ebebfa",
    fontSize: "12px",
    color: "#34344c",
    letterSpacing: "1px",
    border: "1px solid #34344c"
  }
};

const StyledTooltip = withStyles(tooltipStyles)(Tooltip);

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: "30px",
    margin: "5px 10px -10px 5px",
    "&:hover": {
      color: "#ebebfa"
    },
    "&:active": {
      color: "#ffc266"
    }
  },
  navIcon: {
    color: "white"
  },
  avatar: {
    width: "35px",
    height: "35px",
    borderRadius: "50%",
    padding: "0",
    margin: "10px 10px -15px 5px"
  },
  btn1: {
    color: "white",
    height: 45,
    width: 140,
    padding: "10px",
    margin: "10px",
    border: "1px solid white",
    backgroundColor: "#008000",
    letterSpacing: "3px",
    fontSize: "12px",
    "&:hover": {
      fontWeight: 700,
      border: "2px solid #00cc00"
    }
  }
}));

function HeaderLoggedIn(props) {
  const classes = useStyles();

  const appDispatch = useContext(DispatchContext);
  const appState=useContext(StateContext);

  async function handleSignOut(e) {
    e.preventDefault();
    try {
      await Auth.signOut();
      appDispatch({ type: "logout" });
      appDispatch({
         type: "flashMessage",
         value: "You logged out successfully."
       });
    } catch (err) {
      console.log("error signing out", err);
    }
  }


  return (
    <div>
      <StyledTooltip title="Profile" hover>
        <Link
          className={classes.navIcon}
          to={`/profile/${appState.user.username}`}
        >
          <AccountCircleIcon className={classes.avatar} />
        </Link>
      </StyledTooltip>
      <StyledTooltip title="Search" hover>
        <Link className={classes.navIcon} to="/search">
          <SearchIcon className={classes.icon} />
        </Link>
      </StyledTooltip>
      <Button
        className={classes.btn1}
        variant="outlined"
        color="secondary"
        href="/create-post"
      >
        Create Post
      </Button>
      <StyledButton2
        variant="outlined"
        color="secondary"
        href="/"
        onClick={handleSignOut}
      >
        Sign Out
      </StyledButton2>
    </div>
  );
}

export default HeaderLoggedIn;
