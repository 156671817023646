import React from "react";


export default function Loading() {

  return (
    <div className="dots-loading">
      <div></div>
    </div>
  );
}
